<template>
  <div style="text-align: left">团购信息</div>
  <el-form
    :model="addTeamForm"
    :rules="rules"
    ref="ruleForm"
    label-width="100px"
    style="margin-top: 30px"
    class="demo-ruleForm"
  >
    <el-form-item label="团购主题" prop="caption" style="width: 50%">
      <div style="display: flex">
        <el-input
          v-model="addTeamForm.caption"
          clearable
          style="width: 230px"
          placeholder="请输入团购主题"
        ></el-input>
      </div>
    </el-form-item>
    <el-form-item label="团购期数" prop="caption" style="width: 50%">
      <div style="display: flex">
        <el-input
          v-model="addTeamForm.issue"
          clearable
          style="width: 230px"
          placeholder="请输入团购期数"
        ></el-input>
      </div>
    </el-form-item>
    <el-form-item label="团购标题" prop="caption" style="width: 50%">
      <div style="display: flex;flex-direction: column;">
        <el-input
          v-model="addTeamForm.subject"
          clearable
          style="width: 230px"
          placeholder="请输入团购标题"
        ></el-input>
        <span style="color: rgba(0, 0, 0, 0.45); text-align: left"
        >用于小程序团购页面展示</span
      >
      </div>
    </el-form-item>
    <el-form-item
      label="团购时间"
      prop="teamTimeproid"
      style="width: 50%; text-align: left"
    >
      <el-date-picker
        v-model="addTeamForm.teamTimeproid"
        type="datetimerange"
        :shortcuts="shortcuts"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      >
      </el-date-picker>
    </el-form-item>
    <el-form-item
      label="团购图片"
      prop="goodsPic"
      class="goodpic"
      style="width: 50%"
    >
      <el-upload
              :action="UploadUrl"
         :headers="{
           authorization: Token
         }"
        list-type="picture-card"
        style="display: flex; justify-content: flex-start"
         :auto-upload="true"
        :before-upload="handlebeforupload"
        accept=""
        :file-list="teamlist"
        :on-success="filechange"
        :limit="1"
        :on-exceed="handleexceed"
      >
        <template #default>
          <i class="el-icon-plus"></i>
        </template>
        <template #file="{ file }">
          <div style="width: 100%; height: 100%">
            <img
              class="el-upload-list__item-thumbnail"
              :src="file.url"
              alt=""
            />
            <span class="el-upload-list__item-actions">
              <span
                class="el-upload-list__item-delete"
                @click="handleRemove(file)"
              >
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </template>
      </el-upload>
      <span style="color: rgba(0, 0, 0, 0.45); text-align: left"
        >建议宽高比约为3:1、图片大小200k为宜，不同机型可能有所偏差，只可设置一张图片,用于列表页展示,单张图片不超过2M</span
      >
    </el-form-item>
    <el-form-item
      label="九宫格图"
      prop="goodsPic"
      class="goodpic"
      style="width: 50%"
    >
      <el-upload
        action="#"
        list-type="picture-card"
        style="display: flex; justify-content: flex-start"
        :auto-upload="false"
        accept=""
        :file-list="teamgridlist"
        :on-change="fileshowchange"
        :limit="9"
        :on-exceed="handlefridexceed"
      >
        <template #default>
          <i class="el-icon-plus"></i>
        </template>
        <template #file="{ file }">
          <div style="width: 100%; height: 100%">
            <img
              class="el-upload-list__item-thumbnail"
              :src="file.url"
              alt=""
            />
            <span class="el-upload-list__item-actions">
              <span
                class="el-upload-list__item-delete"
                @click="handlegridRemove(file)"
              >
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </template>
      </el-upload>
      <span style="color: rgba(0, 0, 0, 0.45); text-align: left"
        >建议宽高比一致，显示是宽度不变，高度自适应，建议上传3，6，9张图片、图片大小200k为宜，单张图片不超过2M</span
      >
    </el-form-item>
    <el-form-item
      label="活动描述"
      prop="goodInstan"
      style="width: 80%; text-align: left"
    >
     <vue-ueditor-wrap
        v-model="addTeamForm.description"
        :config="editorConfig"
        editor-id="teamInfo"
        style="height: 100%"
      ></vue-ueditor-wrap>
    </el-form-item>
    <!-- <el-form-item
      label="商品选择"
      prop="teamGoods"
      style="width: 50%; text-align: left"
    >
      <el-cascader
        v-model="addTeamForm.teamGoods"
        @change="log"
        :options="options"
        :props="{ multiple: true }"
        clearable
      ></el-cascader>
    </el-form-item> -->
  </el-form>
  <div class="displayflex">
    <el-button
      type="primary"
      size="mini"
      style="margin-left: 30px"
      @click="onsubmit"
      >提交</el-button
    >
  </div>
</template>
<script>
export default {
  data() {
    return {
      addTeamForm: {
        caption: '',
        type: 'GROUP',
        coverUrl: '',
        teamTimeproid: [],
        startDate: '',
        endDate: '',
        subject: '',
        issue: '',
        description: '',
      },
      rules: {
        caption: [
          { required: true, message: '请输入团购主题！', trigger: 'blur' }
        ],
        teamTimeproid: [
          { required: true, message: '请选取团购活动时间！', trigger: 'blur' }
        ],
      },
      baseUrl: '',
      teamlist: [],
      teamgridlist: [],
      gridPicList: [],
      shortcuts: [{
        text: '最近一周',
        value: (() => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() + 24 * 60 * 60 * 1000)
          end.setTime(start.getTime() + 7 * 24 * 60 * 60 * 1000)
          // start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          return [start, end]
        })()
      }],
      isadd: true,
    }
  },
  methods: {
        handlebeforupload(file){
      return new Promise (async(resolve, reject)=>{
          const isLt2M = file.size / 1048576  < 2;
          if(!isLt2M && file.type.indexOf('image')!==-1){
              this.$message.error('上传图片请小于2M')
              reject()
          }else{
            resolve()
          }
      })
    },
    handleexceed() {
      this.$message.error('仅限上传1个媒体文件！')
    },
    handlefridexceed() {
      this.$message.error('仅限上传9个媒体文件！')
    },
    handleRemove(file) {
      this.addTeamForm.coverUrl = ''
      this.teamlist = []
    },
    handlegridRemove(file) {
      console.log(file)
      const list = this.teamgridlist
      var i = list.findIndex(item => item.url === file.url)
      // var arr = this.cousepicList.filter(item => item.url !== file.url)
      // this.cousepicList = arr
      console.log(i)
      list.splice(i, 1)
      this.teamgridlist = list
      // console.log(file.url.split('/'))
      var tmpArr = file.url.split('/')
      var j = this.gridPicList.findIndex(item => item === tmpArr[tmpArr.length - 1])
      this.gridPicList.splice(j, 1)
      // console.log(this.gridPicList)
      // console.log('绑定的文件列表', this.teamgridlist)
    },
    async onsubmit() {
      console.log(this.teamlist)
      const data = {}
      if (this.addTeamForm.coverUrl.trim() === '') {
        this.$message.error('请上传图片！')
        return
      }
      if (this.$route.params.isadd === 'false') {
        data.id = this.addTeamForm.id
        data.caption = this.addTeamForm.caption
        data.type = 'GROUP'
        data.coverUrl = this.addTeamForm.coverUrl
        data.startDate = this.formatdate(this.addTeamForm.teamTimeproid[0])
        data.endDate = this.formatdate(this.addTeamForm.teamTimeproid[1])
        data.issue = this.addTeamForm.issue
        data.subject = this.addTeamForm.subject
        data.description = this.addTeamForm.description
        data.pictureUrl1 = this.gridPicList[0]
        data.pictureUrl2 = this.gridPicList[1] || ''
        data.pictureUrl3 = this.gridPicList[2] || ''
        data.pictureUrl4 = this.gridPicList[3] || ''
        data.pictureUrl5 = this.gridPicList[4] || ''
        data.pictureUrl6 = this.gridPicList[5] || ''
        data.pictureUrl7 = this.gridPicList[6] || ''
        data.pictureUrl8 = this.gridPicList[7] || ''
        data.pictureUrl9 = this.gridPicList[8] || ''
      } else {
        data.caption = this.addTeamForm.caption
        data.type = 'GROUP'
        data.coverUrl = this.addTeamForm.coverUrl
        data.startDate = this.formatdate(this.addTeamForm.teamTimeproid[0])
        data.endDate = this.formatdate(this.addTeamForm.teamTimeproid[1])
        data.issue = this.addTeamForm.issue
        data.subject = this.addTeamForm.subject
        data.description = this.addTeamForm.description
        data.pictureUrl1 = this.gridPicList[0]
        data.pictureUrl2 = this.gridPicList[1] || ''
        data.pictureUrl3 = this.gridPicList[2] || ''
        data.pictureUrl4 = this.gridPicList[3] || ''
        data.pictureUrl5 = this.gridPicList[4] || ''
        data.pictureUrl6 = this.gridPicList[5] || ''
        data.pictureUrl7 = this.gridPicList[6] || ''
        data.pictureUrl8 = this.gridPicList[7] || ''
        data.pictureUrl9 = this.gridPicList[8] || ''
      }

      console.log(data)
      const res = await this.$http.post('/nktdec/salesactivity/salesactivities', data)
      console.log(res)
      if (res.data.code === '1') {
        this.$message.success('团购设置成功！')
        this.$router.push('/teaminfo')
      }
    },
    formatdate(date) {
      var datestr = date.toLocaleDateString().split('/').join('-')
      var str = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      str += ':'
      str += date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      str += ':'
      str += date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      return datestr + ' ' + str
    },
    async filechange(res,file, fileList) {
      this.teamlist = fileList
      // const formData = new FormData()
      // formData.append('file', file.raw)
      // formData.append('params', '参数')
      // const res = await this.$http(
      //   {
      //     method: 'post',
      //     url: '/nktdec/uploader/uploadfile',
      //     data: formData,
      //     headers: {
      //       'Content-Type': 'multipart/form-data;',
      //     }
      //   }
      // )
      console.log(res)
      // const coverurl = await this.$http.get('/nktdec/uploader/images/' + res.data.result)
      // console.log(this.baseUrl + coverurl.config.url.substring(1))
      this.addTeamForm.coverUrl = res.result
    },
    async fileshowchange(file, fileList) {
      this.teamgridlist = fileList
      const formData = new FormData()
      formData.append('file', file.raw)
      formData.append('params', '参数')
      const res = await this.$http(
        {
          method: 'post',
          url: '/nktdec/uploader/uploadfile',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data;',
          }
        }
      )
      // console.log(res)
      // const coverurl = await this.$http.get('/nktdec/uploader/images/' + res.data.result)
      // console.log(this.baseUrl + coverurl.config.url.substring(1))
      this.gridPicList.push(res.data.result)
    },
  },
  computed: {
    TeamWorkAc() {
      return this.$store.state.TeamWorkAc
    },
    editorConfig() {
      return this.$store.state.editorConfig
    },
    imgBaseUrl() {
      return this.$store.state.imgBaseUrl
    },
        Token() {
      return this.$store.state.Token
    },
    UploadUrl() {
      return this.$store.state.UploadUrl
    },
  },
  created() {
    const Arr = [
      {
        name: '首页',
        index: '/index'
      },
      {
        name: '活动管理',
        index: '/teammange'
      },
      {
        name: '团购管理',
        index: '/teammange'
      }, {
        name: '团购列表',
        index: '/teaminfo'
      }, {
        name: '团购编辑',
        index: ''
      }]
    this.$store.commit('setBreadInfo', Arr)
    this.baseUrl = this.$http.defaults.baseURL

    if (this.$route.params.isadd === 'false') {
      this.isadd = false
      this.addTeamForm = this.TeamWorkAc
      this.addTeamForm.teamTimeproid = []
      this.addTeamForm.teamTimeproid.push(new Date(this.addTeamForm.startDate))
      this.addTeamForm.teamTimeproid.push(new Date(this.addTeamForm.endDate))
      console.log(this.addTeamForm)
      this.teamlist.push({
        url: this.imgBaseUrl + this.addTeamForm.coverUrl
      })
      this.gridPicList = []
      const list = []
      setTimeout(async () => {
        for (let i = 1; i < 9; i++) {
          if (this.addTeamForm['pictureUrl' + i].trim() !== '') {
            // const result = await this.$http.get('/nktdec/uploader/images/' + this.addgoodForm['pictureUrl' + i])
            // console.log(result)
            // this.goodbasefileList[i - 1] = {
            //   url: this.baseUrl + result.config.url.substring(1)
            // }
            list.push({
              url: this.imgBaseUrl + this.addTeamForm['pictureUrl' + i]
            })
            this.gridPicList.push(this.addTeamForm['pictureUrl' + i])
            // console.log(this.gridPicList)
          } else {
            break
          }
        }
        this.teamgridlist = list
      }, 0)
      // console.log(this.teamlist)
    }
  },
}
</script>
<style scoped>
</style>
