<template>
  <div style="display: flex; justify-content: flex-start">
    <ul class="libox">
      <li
        v-for="(item, index) in licon"
        @click="activeli(index)"
        :key="index"
        :class="currentIndex === index ? 'itemli activeli' : 'itemli '"
      >
        {{ item }}
      </li>
    </ul>
    <el-card style="flex: 1; margin: 0 20px" class="cardbox">
      <router-view />
    </el-card>
  </div>
</template>
<script>
import { onMounted } from 'vue'

export default {
  setup() {
    onMounted(
      () => {
        document.querySelector('.libox').style.minHeight = window.innerHeight + 'px'
        window.onresize = () => {
          return (() => {
            document.querySelector('.libox').style.minHeight = window.innerHeight + 'px'
          })()
        }
      }
    )
  },
  data() {
    return {
      licon: ['团购管理', '直播管理'],
      currentIndex: 0
    }
  },
  methods: {
    activeli(i) {
      this.currentIndex = i
      if (i === 0) {
        this.$router.push('/teammange')
      } else if (i === 1) {
        this.$router.push('/liveinfo')
      }
    }
  }
}
</script>
<style scoped>
.activeli {
  background-color: #f5f5f5;
}
.libox li {
  height: 50px;
  line-height: 50px;
  font-size: 13px;
}
.itemli {
  cursor: pointer;
}
.itemli:hover {
  color: #1890ff;
}
.libox {
  width: 120px;
  line-height: 0;
  margin: 0;
  padding: 0;
  background-color: #fff;
  margin-left: -20px;
  margin-top: -20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
</style>
