<template>
  <div style="text-align: left">直播活动</div>
  <el-form
    :inline="true"
    :model="seachForm"
    style="margin-top: 20px; display: flex; justify-content: flex-start"
    class="lineform"
  >
    <el-form-item label="直播状态">
      <el-select
        v-model="seachForm.state"
        clearable
        size="small"
        placeholder="请选择直播状态"
      >
        <el-option
          v-for="(item, index) in stateOption"
          :key="index"
          :label="item.label"
          :value="item.key"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-button
        type="primary"
        @click="onSubmit"
        size="mini"
        style="margin-left: 20px"
        >查询</el-button
      >
      <el-button
        type="primary"
        @click="clearall"
        size="mini"
        style="margin-left: 20px"
        >清空</el-button
      >
    </el-form-item>
  </el-form>
  <div style="display: flex; justify-content: flex-start">
    <el-button type="primary" size="mini" @click="addTeam">新增活动</el-button>
  </div>
  <el-empty v-if="tableData.length === 0" description="暂无数据"></el-empty>

  <el-table
    v-else-if="tableData.length >= 0"
    :data="tableData"
    stripe
    style="width: 100%; margin-top: 30px"
  >
    <el-table-column type="index" label="#"> </el-table-column>
    <el-table-column prop="caption" label="直播主题" width="100px">
    </el-table-column>
    <el-table-column prop="roomNo" label="房间号" width="100px">
    </el-table-column>
    <el-table-column prop="coverUrl" label="直播图片" width="150px">
      <template #default="scope">
        <img
          :src="imgBaseUrl + scope.row.coverUrl"
          style="width: 80px; height: 120px; object-fit: contain"
          alt=""
        />
      </template>
    </el-table-column>
      <el-table-column prop="issue" label="期数" width="120px">
    </el-table-column>
      <el-table-column prop="subject" label="标题" width="180px">
    </el-table-column>
    <el-table-column prop="startDate" label="开始时间" width="180px">
    </el-table-column>
    <el-table-column prop="endDate" label="结束时间" width="180px">
    </el-table-column>
    <!-- <el-table-column prop="createDate" label="创建时间" width="150px">
    </el-table-column> -->
    <el-table-column prop="state" label="活动状态" width="180px">
      <template #default="scope">
        <el-tag type="info" v-if="scope.row.activityState === 'CREATED'"
          >待开始</el-tag
        >
        <el-tag v-if="scope.row.activityState === 'DOING'">已开始</el-tag>
        <el-tag type="success" v-if="scope.row.activityState === 'DONE'"
          >已完成</el-tag
        >
        <el-tag type="warning" v-if="scope.row.activityState === 'CANCELLED'"
          >已取消</el-tag
        >
      </template>
    </el-table-column>
    <el-table-column label="操作" width="300px">
      <template #default="scope">
        <div
          style="
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            flex-wrap: wrap;
          "
        >
          <!-- <div>
            <el-button type="text" @click="editgood(scope.row)"
              >商品管理</el-button
            >
          </div> -->
          <!-- <div> -->
          <div>
            <el-button type="text" @click="pushtogoodsmange(scope.row)"
              >商品管理</el-button
            >
            <el-button
              type="text"
              @click="edittemework(scope.row)"
              v-if="
                scope.row.activityState !== 'DONE' &&
                scope.row.activityState !== 'CANCELLED'
              "
              >编辑活动</el-button
            >
            <el-button
              type="text"
              @click="copyteamact(scope.row)"
              class="marginleft10"
              >复制活动</el-button
            >
          </div>

          <div>
            <el-button
              type="text"
              @click="submitSwiper(scope.row)"
              v-if="
                scope.row.activityState !== 'DONE' &&
                scope.row.activityState !== 'CANCELLED'
              "
              class="marginleft10"
              >设置轮播</el-button
            >
            <el-button
              type="text"
              @click="cancelact(scope.row)"
              class="marginleft10"
              v-if="scope.row.activityState === 'DOING'"
              >终止活动</el-button
            >
            <el-button
              type="text"
              @click="deleteteam(scope.row)"
              v-if="scope.row.activityState === 'CREATED'"
              >删除活动</el-button
            >
          </div>
        </div>
      </template>
    </el-table-column>
  </el-table>
  <el-dialog title="复制活动的信息" v-model="dialogVisible" width="600px">
    <el-form ref="form" :model="copyform" size="small" label-width="90px">
      <el-form-item
        label="直播房间号"
        prop="roomNo"
        style="width: 50%; text-align: left"
      >
        <div style="display: flex">
          <el-input
            style="width: 230px"
            clearable
            v-model="copyform.roomNo"
            placeholder="请输入直播房间号"
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item
        label="直播时间"
        label-width="90px"
        prop="teamTimeproid"
        style="width: 50%; text-align: left"
      >
        <el-date-picker
          v-model="copyform.timepriod"
          type="datetimerange"
          size="small"
          :shortcuts="shortcuts"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
         <el-form-item
        label="团购期数"
        prop="teamTimeproid"
        style="width: 50%; text-align: left"
      >
       <el-input
          v-model="copyform.issue"
          clearable
          style="width: 230px"
          placeholder="请输入团购期数"
        ></el-input>
      </el-form-item>
       <el-form-item
        label="团购标题"
        prop="teamTimeproid"
        style="width: 50%; text-align: left"
      >
       <el-input
          v-model="copyform.subject"
          clearable
          style="width: 230px"
          placeholder="请输入团购标题"
        ></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="submitcopyac"
          >确 定</el-button
        >
      </span>
    </template>
  </el-dialog>
  <!-- 分页组件 -->
  <el-pagination
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page="currentPage"
    :page-sizes="[10, 15, 20]"
    :page-size="pageSize"
    layout="total, sizes, prev, pager, next, jumper"
    style="margin-top: 20px"
    :total="totalElement"
  >
  </el-pagination>
</template>
<script>
export default {
  data() {
    return {
      seachForm: {
        state: '',
      },
      copyform: {
        timepriod: '',
        startDate: '',
        endDate: '',
        roomNo: '',
         issue: '',
        subject: ''
      },
      shortcuts: [{
        text: '最近一周',
        value: (() => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() + 24 * 60 * 60 * 1000)
          end.setTime(start.getTime() + 7 * 24 * 60 * 60 * 1000)
          // start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          return [start, end]
        })()
      }],
      dialogVisible: false,
      tableData: [],
      copyrowId: '',
      stateOption: [],
      currentPage: 1,
      totalElement: 0,
      pageSize: 0,
    }
  },
  created() {
    const Arr = [
      {
        name: '首页',
        index: '/index'
      },
      {
        name: '活动管理',
        index: '/liveinfo'
      },
      {
        name: '直播管理',
        index: '/liveinfo'
      }, {
        name: '直播列表',
        index: ''
      }]
    this.$store.commit('setBreadInfo', Arr)
    this.getTableData(this.LiveSeachForm)
    this.getactstate()
  },
  methods: {
    async handleSizeChange(val) {
      console.log(val)
      const res = await this.$http.get('/nktdec/salesactivity/salesactivities/1' + '?type=LIVE&pageSize=' + val + (this.seachForm.state.trim() === '' ? '' : '&state=' + this.seachForm.state))
      this.tableData = res.data.content
      console.log(res)
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
      this.currentPage = 1
      // this.getimgUrl()
      // this.getClassinfo()
      this.$store.commit('setLiveSeachForm',{...this.LiveSeachForm , currentpage : '1' , pageSize: val})

    },
    async handleCurrentChange(val) {
      console.log(val)
      var str = val + '?type=LIVE&pageSize=' + this.pageSize + (this.seachForm.state.trim() === '' ? '' : '&state=' + this.seachForm.state)
      const res = await this.$http.get('/nktdec/salesactivity/salesactivities/' + str)
      this.tableData = res.data.content
      console.log(res)
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
      // this.getimgUrl()
      // this.getClassinfo()
      this.currentPage = val
      this.$store.commit('setLiveSeachForm',{...this.LiveSeachForm , currentpage : val , pageSize: this.pageSize })
    },
  query(str,obj){
      if(obj.currentpage){
           str+= obj.currentpage+'?type=LIVE'
           for(let i in obj){
            if(i==='currentpage') {
                continue
            }else{
              if(obj[i]!==''){
              str+='&'+i+'='+ obj[i]
            }
            }
         }
      }else{
        str+='1?type=LIVE'
      }
      return str 
    },
    async getTableData(obj) {
      var str = this.query('',obj)
      console.log(str)
      if(obj.state){
        this.seachForm.state = obj.state
      }
      const res = await this.$http.get('/nktdec/salesactivity/salesactivities/' + str)
      console.log(res.data.content)
      this.tableData = res.data.content || []
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
      this.currentPage = obj.currentpage - 0
      this.$store.commit('setLiveSeachForm',{...this.LiveSeachForm , currentpage : obj.currentpage , pageSize: '20'})
    },
    async getactstate() {
      const res = await this.$http.get('/nktdec/salesactivity/activitystates')
      console.log(res.data)
      for (let i = 0; i < res.data.length; i++) {
        var keys = Object.keys(res.data[i])
        var vals = Object.values(res.data[i])
        this.stateOption.push({
          key: keys[0],
          label: vals[0]
        })
      }
      console.log(this.stateOption)
    },
    async onSubmit() {
      var str = (this.seachForm.state.trim() === '' ? '' : '&state=' + this.seachForm.state)
      console.log(str)
      const res = await this.$http.get('/nktdec/salesactivity/salesactivities/1?type=LIVE' + str)
      console.log(res.data)
      this.tableData = res.data.content || []
          this.currentPage = 1
            this.$store.commit('setLiveSeachForm',{...this.seachForm , currentpage : '1' , pageSize: '20'})

      this.getTableData(this.LiveSeachForm)
    },
    async clearall() {
      const res = await this.$http.get('/nktdec/salesactivity/salesactivities/1?type=LIVe')
      console.log(res.data)
      this.tableData = res.data.content
      this.currentPage = 1
      this.$store.commit('setLiveSeachForm',{...this.seachForm , currentpage : '1' , pageSize: '20'})

      this.getTableData(this.LiveSeachForm)
    },
    addTeam() {
      this.$router.push('/livegood/true')
      console.log('正在新增活动')
    },
    pushtogoodsmange(row) {
      console.log(row)
      this.$router.push('/livegoodsmange/' + row.id)
    },
    copyteamact(row) {
      this.copyrowId = row.id
      this.dialogVisible = true
    },
    stopteamwork(row) {
      if (row.TeamStatus === 'start') {
        console.log('已中止')
      } else if (row.TeamStatus === 'waitstart') {
        console.log('已取消')
      }
    },
    deleteteam(row) {
      console.log('正在删除活动', row)
      this.$confirm('此操作将永久删除该直播活动, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await this.$http.delete('/nktdec/salesactivity/salesactivity/' + row.id)
        console.log(res)
        if (res.data.code === '1') {
          this.getTableData(this.LiveSeachForm)
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    },
    edittemework(row) {
      this.$store.commit('setTeamWorkAc', row)
      this.$router.push('/livegood/false')
    },
    async submitSwiper(row) {
      console.log('设置轮播', row)
      const res = await this.$http.post('/nktdec/salesactivity/banner/' + row.id)
      console.log(res)
      if (res.data.code === '1') {
        this.$message({
          type: 'success',
          message: '设置成功！'
        })
      }
    },
    async cancelact(row) {
      if (row.state !== 'DOING') {
        return false
      }
      const res = await this.$http.get('/nktdec/salesactivity/salesactivity/' + row.id)
      console.log(res)
    },
    async submitcopyac() {
      if (this.copyform.timepriod) {
        const data = {
          startDate: this.formatdate(this.copyform.timepriod[0]),
          endDate: this.formatdate(this.copyform.timepriod[1]),
          roomNo: this.copyform.roomNo,
          subject: this.copyform.subject,
          issue: this.copyform.issue
        }
        const res = await this.$http.post('/nktdec/salesactivity/salesactivity/' + this.copyrowId, data)
        console.log(res)
        if (res.data.code === '1') {
          this.$message.success('活动复制成功！')
          this.getTableData(this.LiveSeachForm)
        }
      }
      this.dialogVisible = false
    },
    formatdate(date) {
      var datestr = date.toLocaleDateString().split('/').join('-')
      var str = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      str += ':'
      str += date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      str += ':'
      str += date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      return datestr + ' ' + str
    },
  },
  computed: {
    imgBaseUrl() {
      return this.$store.state.imgBaseUrl
    },
    LiveSeachForm() {
      return this.$store.state.LiveSeachForm
    },
  }
}
</script>
<style scoped>
</style>
