<template>
  <div style="text-align: left">团购活动</div>
  <el-form
    :inline="true"
    :model="seachForm"
    style="margin-top: 20px; display: flex; justify-content: flex-start"
    class="lineform"
  >
    <el-form-item label="团购状态">
      <el-select
        v-model="seachForm.state"
        clearable
        size="small"
        placeholder="请选择团购状态"
      >
        <el-option
          v-for="(item, index) in stateOption"
          :key="index"
          :label="item.label"
          :value="item.key"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-button
        type="primary"
        @click="onSubmit"
        size="mini"
        style="margin-left: 20px"
        >查询</el-button
      >
      <el-button
        type="primary"
        @click="clearall"
        size="mini"
        style="margin-left: 20px"
        >清空</el-button
      >

    </el-form-item>
  </el-form>
  <div style="display: flex; justify-content: flex-start">
    <el-button type="primary" size="mini" @click="addTeam">新增活动</el-button>
  </div>
  <el-empty v-if="tableData.length === 0" description="暂无数据"></el-empty>

  <el-table
    v-else-if="tableData.length >= 0"
    :data="tableData"
    stripe
    style="width: 100%; margin-top: 30px"
  >
    <el-table-column type="index" label="#"> </el-table-column>
    <el-table-column prop="caption" label="团购主题" width="200px">
    </el-table-column>
    <el-table-column prop="coverUrl" label="团购图片" width="150px">
      <template #default="scope">
        <img
          :src="imgBaseUrl + scope.row.coverUrl"
          style="width: 80px; height: 120px; object-fit: contain"
          alt=""
        />
      </template>
    </el-table-column>
      <el-table-column prop="issue" label="期数" width="120px">
    </el-table-column>
      <el-table-column prop="subject" label="标题" width="180px">
    </el-table-column>
    <el-table-column prop="startDate" label="开始时间" width="180px">
    </el-table-column>
    <el-table-column prop="endDate" label="结束时间" width="180px">
    </el-table-column>
    <!-- <el-table-column prop="createDate" label="创建时间" width="150px">
    </el-table-column> -->
    <el-table-column prop="state" label="活动状态" width="180px">
      <template #default="scope">
        <el-tag type="info" v-if="scope.row.activityState === 'CREATED'"
          >待开始</el-tag
        >
        <el-tag v-if="scope.row.activityState === 'DOING'">已开始</el-tag>
        <el-tag type="success" v-if="scope.row.activityState === 'DONE'"
          >已完成</el-tag
        >
        <el-tag type="warning" v-if="scope.row.activityState === 'CANCELLED'"
          >已取消</el-tag
        >
      </template>
    </el-table-column>

    <el-table-column prop="address" label="操作" width="300px">
      <template #default="scope">
        <div
          style="
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            flex-wrap: wrap;
          "
        >
          <!-- <div>
            <el-button type="text" @click="editgood(scope.row)"
              >商品管理</el-button
            >
          </div> -->
          <!-- <div> -->
          <div>
            <el-button type="text" @click="pushtogoodsmange(scope.row)"
              >商品管理</el-button
            >
            <el-button
              type="text"
              @click="edittemework(scope.row)"
              v-if="
                scope.row.activityState !== 'DONE' &&
                scope.row.activityState !== 'CANCELLED'
              "
              >编辑活动</el-button
            >
            <el-button
              type="text"
              @click="copyteamact(scope.row)"
              class="marginleft10"
              >复制活动</el-button
            >
          </div>

          <div>
            <el-button
              type="text"
              @click="submitSwiper(scope.row)"
              v-if="
                scope.row.activityState !== 'DONE' &&
                scope.row.activityState !== 'CANCELLED'
              "
              class="marginleft10"
              >设置轮播</el-button
            >
            <el-button
              type="text"
              @click="cancelact(scope.row)"
              class="marginleft10"
              v-if="scope.row.activityState === 'DOING'"
              >终止活动</el-button
            >
            <el-button
              type="text"
              @click="deleteteam(scope.row)"
              v-if="scope.row.activityState === 'CREATED'"
              >删除活动</el-button
            >
          </div>
        </div>
      </template>
    </el-table-column>
  </el-table>
  <el-dialog title="复制活动" v-model="dialogVisible" width="1300px">
    <el-form ref="form" :model="copyform" size="small" label-width="80px">
      <el-form-item
        label="团购时间"
        prop="teamTimeproid"
        style="width: 50%; text-align: left"
      >
        <el-date-picker
          v-model="copyform.timepriod"
          type="datetimerange"
          size="small"
          :shortcuts="shortcuts"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
       <el-form-item
        label="团购期数"
        prop="teamTimeproid"
        style="width: 50%; text-align: left"
      >
      <div class="inputspanbox">
         <el-input
          v-model="copyform.issue"
          clearable
          style="width: 230px"
          placeholder="请输入团购期数"
        ></el-input>
        <span style="color: rgba(0, 0, 0, 0.45); text-align: left"
        >团购期数不可相同！！！</span
      >
      </div>
      
      </el-form-item>
       <el-form-item
        label="团购主题"
        prop="teamTimeproid"
        style="width: 50%; text-align: left"
      >
       <el-input
          v-model="copyform.subject"
          clearable
          style="width: 230px"
          placeholder="请输入团购主题"
        ></el-input>
      </el-form-item>
        <el-form-item
        label="团购标题"
        prop="teamTimeproid"
        style="width: 50%; text-align: left"
      >
       <el-input
          v-model="copyform.caption"
          clearable
          style="width: 230px"
          placeholder="请输入团购标题"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="团购图片"
        prop="teamTimeproid"
        class="goodpic"
        style="width: 100%; text-align: left"
      >
      <el-upload
           :action="UploadUrl"
         :headers="{
           authorization: Token
         }"
        list-type="picture-card"
        style="display: flex; justify-content: flex-start"
        :auto-upload="true"
        :before-upload="handlebeforupload"
        accept=""
        :file-list="teamlist"
        :on-success="filechange"
        :limit="1"
        :on-exceed="handleexceed"
      >
        <template #default>
          <i class="el-icon-plus"></i>
        </template>
        <template #file="{ file }">
          <div style="width: 100%; height: 100%">
            <img
              class="el-upload-list__item-thumbnail"
              :src="file.url"
              alt=""
            />
            <span class="el-upload-list__item-actions">
              <span
                class="el-upload-list__item-delete"
                @click="handleRemove(file)"
              >
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </template>
      </el-upload>
      <span style="color: rgba(0, 0, 0, 0.45); text-align: left"
        >建议宽高比约为3:1、图片大小200k为宜，不同机型可能有所偏差，只可设置一张图片，单张图片不超过2M</span
      >
      </el-form-item>
       <el-form-item
      label="九宫格图"
      prop="goodsPic"
      class="goodpic"
      style="width: 50%"
    >
      <el-upload
          :action="UploadUrl"
         :headers="{
           authorization: Token
         }"
        list-type="picture-card"
        style="display: flex; justify-content: flex-start"
        :auto-upload="false"
        accept=""
        :file-list="teamgridlist"
        :on-success="fileshowchange"
        :limit="9"
        :on-exceed="handlegridexceed"
      >
        <template #default>
          <i class="el-icon-plus"></i>
        </template>
        <template #file="{ file }">
          <div style="width: 100%; height: 100%">
            <img
              class="el-upload-list__item-thumbnail"
              :src="file.url"
              alt=""
            />
            <span class="el-upload-list__item-actions">
              <span
                class="el-upload-list__item-delete"
                @click="handlegridRemove(file)"
              >
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </template>
      </el-upload>
      <span style="color: rgba(0, 0, 0, 0.45); text-align: left"
        >建议宽高比一致，显示是宽度不变，高度自适应，建议上传3，6，9张图片、图片大小200k为宜，单张图片不超过2M</span
      >
    </el-form-item>
      <el-form-item
      label="活动描述"
      prop="goodInstan"
      style="width: 80%; text-align: left"
    >
     <vue-ueditor-wrap
        v-model="copyform.description"
        :config="editorConfig"
        style="height: 100%"
      ></vue-ueditor-wrap>
    </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="submitcopyac"
          >确 定</el-button
        >
      </span>
    </template>
  </el-dialog>
  <!-- 分页组件 -->
  <el-pagination
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page="currentPage"
    :page-sizes="[10, 15, 20]"
    :page-size="pageSize"
    layout="total, sizes, prev, pager, next, jumper"
    style="margin-top: 20px"
    :total="totalElement"
  >
  </el-pagination>
</template>
<script>
export default {
  data() {
    return {
      seachForm: {
        state: '',
      },
      copyform: {
        timepriod: '',
        startDate: '',
        endDate: '',
        issue: '',
        subject: '',
        description: '',
        coverUrl: '',
        caption: ''
      },
      shortcuts: [{
        text: '最近一周',
        value: (() => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() + 24 * 60 * 60 * 1000)
          end.setTime(start.getTime() + 7 * 24 * 60 * 60 * 1000)
          // start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          return [start, end]
        })()
      }],
      dialogVisible: false,
      tableData: [],
      copyrowId: '',
      stateOption: [],
      currentPage: 1,
      totalElement: 0,
      pageSize: 0,
      teamlist: [],
      teamgridlist: [],
      gridPicList: [],
      // nearOption:[],
      // expotissue: -1
    }
  },
  created() {
    const Arr = [
      {
        name: '首页',
        index: '/index'
      },
      {
        name: '活动管理',
        index: '/teammange'
      },
      {
        name: '团购管理',
        index: '/teammange'
      }, {
        name: '团购列表',
        index: '/teaminfo'
      }]
    this.$store.commit('setBreadInfo', Arr)
    this.getTableData(this.TeamSeachForm)
    this.getactstate()
    // this.getnearInfo()
  },
  methods: {
    handlebeforupload(file){
      return new Promise (async(resolve, reject)=>{
          const isLt2M = file.size / 1048576  < 2;
          if(!isLt2M && file.type.indexOf('image')!==-1){
              this.$message.error('上传图片请小于2M')
              reject()
          }else{
            resolve()
          }
      })
    },
    async handleSizeChange(val) {
      console.log(val)
      const res = await this.$http.get('/nktdec/salesactivity/salesactivities/1' + '?type=GROUP&pageSize=' + val + (this.seachForm.state.trim() === '' ? '' : '&productName=' + this.seachForm.state))
      this.tableData = res.data.content
      console.log(res)
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
      this.currentPage = 1
      this.$store.commit('setTeamSeachForm',{...this.TeamSeachForm , currentpage : '1' , pageSize: val})
    },
    async handleCurrentChange(val) {
      console.log(val)
      var str = val + '?type=GROUP&pageSize=' + this.pageSize + (this.seachForm.state.trim() === '' ? '' : '&productName=' + this.seachForm.state)
      const res = await this.$http.get('/nktdec/salesactivity/salesactivities/' + str)
      this.tableData = res.data.content
      console.log(res)
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
      this.currentPage = val
      this.$store.commit('setTeamSeachForm',{...this.TeamSeachForm , currentpage : val , pageSize: this.pageSize })
    },
    query(str,obj){
      if(obj.currentpage){
           str+= obj.currentpage+'?type=GROUP'
           for(let i in obj){
            if(i==='currentpage') {
                continue
            }else{
              if(obj[i]!==''){
              str+='&'+i+'='+ obj[i]
            }
            }
         }
      }else{
        str+='1?type=GROUP'
      }
      return str 
    },
    async getactstate() {
      const res = await this.$http.get('/nktdec/salesactivity/activitystates')
      // console.log(res.data)
      for (let i = 0; i < res.data.length; i++) {
        var keys = Object.keys(res.data[i])
        var vals = Object.values(res.data[i])
        this.stateOption.push({
          key: keys[0],
          label: vals[0]
        })
      }
      // console.log(this.stateOption)
    },
    async getTableData(obj) {
      var str = this.query('',obj)
      if(obj.state){
        this.seachForm.state = obj.state
      }
      const res = await this.$http.get('/nktdec/salesactivity/salesactivities/' + str)
      console.log(res.data.content)
      this.tableData = res.data.content
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
      this.currentPage = obj.currentpage - 0
      this.$store.commit('setTeamSeachForm',{...this.TeamSeachForm , currentpage : obj.currentpage , pageSize: '20'})
    },
    async onSubmit() {
      var str = (this.seachForm.state.trim() === '' ? '' : '&state=' + this.seachForm.state)
      console.log(str)
      const res = await this.$http.get('/nktdec/salesactivity/salesactivities/1?type=GROUP' + str)
      console.log(res.data)
      this.tableData = res.data.content
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
      this.currentPage = 1
      this.$store.commit('setTeamSeachForm',{...this.seachForm , currentpage : '1' , pageSize: '20'})
    },
    async clearall() {
      const res = await this.$http.get('/nktdec/salesactivity/salesactivities/1?type=GROUP')
      this.seachForm.state = ''
      console.log(res.data)
      this.tableData = res.data.content
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
      this.currentPage = 1
      this.$store.commit('setTeamSeachForm',{...this.seachForm , currentpage : '1' , pageSize: '20'})

    },
    addTeam() {
      this.$router.push('/addteam/true')
      console.log('正在新增活动')
    },
    pushtogoodsmange(row) {
      console.log(row)
      this.$router.push('/goodsmange/' + row.id)
    },
    copyteamact(row) {
      this.copyrowId = row.id
      this.copyform = row
      this.copyform.timepriod= [new Date(row.startDate),new Date(row.endDate)]
      this.teamgridlist = []
      this.teamlist = []
      this.gridPicList = []
      this.teamlist = [{
        url: this.imgBaseUrl + row.coverUrl
      }]
      for(let i = 0;i<8;i++){
        if(row['pictureUrl'+(i+1)]){
          this.teamgridlist.push({
            url:this.imgBaseUrl + row['pictureUrl'+(i+1)]
          })
          this.gridPicList.push(row['pictureUrl'+(i+1)])
        }
      }
      console.log(this.teamgridlist,this.copyform)
      this.dialogVisible = true
    },
    stopteamwork(row) {
      if (row.TeamStatus === 'start') {
        console.log('已中止')
      } else if (row.TeamStatus === 'waitstart') {
        console.log('已取消')
      }
    },
    deleteteam(row) {
      if (row.state !== 'CREATED') {
        return false
      }
      console.log('正在删除活动', row)
      this.$confirm('此操作将永久删除该团购活动, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await this.$http.delete('/nktdec/salesactivity/salesactivity/' + row.id)
        console.log(res)
        if (res.data.code === '1') {
          this.getTableData(this.TeamSeachForm)
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    },
    edittemework(row) {
      window.sessionStorage.setItem('TeamWorkAc', JSON.stringify(row))
      this.$store.commit('setTeamWorkAc', row)
      this.$router.push('/addteam/false')
    },
    async submitSwiper(row) {
      console.log('设置轮播', row)
      const res = await this.$http.post('/nktdec/salesactivity/banner/' + row.id)
      console.log(res)
      if (res.data.code === '1') {
        this.$message({
          type: 'success',
          message: '设置成功！'
        })
      }
    },
    async cancelact(row) {
      if (row.state !== 'DOING') {
        return false
      }
      const res = await this.$http.put('/nktdec/salesactivity/salesactivity/' + row.id)
      console.log(res)
      this.getTableData(this.TeamSeachForm)
    },
    async submitcopyac() {
      if (this.copyform.timepriod) {
        const data = {
          startDate: this.formatdate(this.copyform.timepriod[0]),
          endDate: this.formatdate(this.copyform.timepriod[1]),
          issue: this.copyform.issue,
          subject: this.copyform.subject,
          description: this.copyform.description,
          caption: this.copyform.caption,
          coverUrl: this.copyform.coverUrl,
          pictureUrl1: this.gridPicList[0],
          pictureUrl2: this.gridPicList[1] || '',
          pictureUrl3: this.gridPicList[2] || '',
          pictureUrl4: this.gridPicList[3] || '',
          pictureUrl5: this.gridPicList[4] || '',
          pictureUrl6: this.gridPicList[5] || '',
          pictureUrl7: this.gridPicList[6] || '',
          pictureUrl8: this.gridPicList[7] || '',
          pictureUrl9: this.gridPicList[8] || '',
        }
        const res = await this.$http.post('/nktdec/salesactivity/salesactivity/' + this.copyrowId, data)
        console.log(res)
        if (res.data.code === '1') {
          this.$message.success('活动复制成功！')
          this.getTableData(this.TeamSeachForm)
        } else {
          this.$message.error('请核对团购期数无误!')
          return
        }
      }
      this.dialogVisible = false
    },
    formatdate(date) {
      var datestr = date.toLocaleDateString().split('/').join('-')
      var str = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      str += ':'
      str += date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      str += ':'
      str += date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      return datestr + ' ' + str
    },
    async filechange(res,file, fileList) {
      this.teamlist = fileList
      // const formData = new FormData()
      // formData.append('file', file.raw)
      // formData.append('params', '参数')
      // const res = await this.$http(
      //   {
      //     method: 'post',
      //     url: '/nktdec/uploader/uploadfile',
      //     data: formData,
      //     headers: {
      //       'Content-Type': 'multipart/form-data;',
      //     }
      //   }
      // )
      // console.log(res)
      // const coverurl = await this.$http.get('/nktdec/uploader/images/' + res.data.result)
      // console.log(this.baseUrl + coverurl.config.url.substring(1))
      this.copyform.coverUrl = res.result
    },
    async fileshowchange(res,file, fileList) {
      this.teamgridlist = fileList
      // const formData = new FormData()
      // formData.append('file', file.raw)
      // formData.append('params', '参数')
      // const res = await this.$http(
      //   {
      //     method: 'post',
      //     url: '/nktdec/uploader/uploadfile',
      //     data: formData,
      //     headers: {
      //       'Content-Type': 'multipart/form-data;',
      //     }
      //   }
      // )
      // console.log(res)
      // const coverurl = await this.$http.get('/nktdec/uploader/images/' + res.data.result)
      // console.log(this.baseUrl + coverurl.config.url.substring(1))
      this.gridPicList.push(res.result)
    },
    handleexceed() {
      this.$message.error('仅限上传1个媒体文件！')
    },
    handlegridexceed() {
      this.$message.error('仅限上传9个媒体文件！')
    },
    handleRemove(file) {
      this.teamlist.coverUrl = ''
      this.teamlist = []
    },
    handlegridRemove(file) {
      console.log(file)
      const list = this.teamgridlist
      var i = list.findIndex(item => item.url === file.url)
      // var arr = this.cousepicList.filter(item => item.url !== file.url)
      // this.cousepicList = arr
      console.log(i)
      list.splice(i, 1)
      this.teamgridlist = list
      // console.log(file.url.split('/'))
      var tmpArr = file.url.split('/')
      var j = this.gridPicList.findIndex(item => item === tmpArr[tmpArr.length - 1])
      this.gridPicList.splice(j, 1)
      // console.log(this.gridPicList)
      // console.log('绑定的文件列表', this.teamgridlist)
    },
  },
  computed: {
    imgBaseUrl() {
      return this.$store.state.imgBaseUrl
    },
    editorConfig() {
      return this.$store.state.editorConfig
    },
    TeamSeachForm() {
      return this.$store.state.TeamSeachForm
    },
    Token() {
      return this.$store.state.Token
    },
    UploadUrl() {
      return this.$store.state.UploadUrl
    },
  }
}
</script>
<style scoped>
.inputspanbox{
  display: flex;
  flex-direction: column;
}
</style>
